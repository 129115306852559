import { setSubscriptionOptions } from '../store/subscriptionOptionSlice'
import { getBillingPlanCall } from './restCalls'

export const loadSubscriptionPlans = async (dispatch: any) => {
    var planIdsToOffer: string[]
    var otherPlansToLoad: string[]
    if (process.env.REACT_APP_STAGE === process.env.REACT_APP_NAME_OF_PROD_STAGE) {
        planIdsToOffer = ['P-52J18575KS683712HM63QMUQ', 'P-04978317X37800357MVGGAQQ', 'P-9PM92809LR3522351MVGGBSY'] // normal price with monthly trial
        //planIdsToOffer = ['P-0YM25124MH876754DMUIXL4I', 'P-04978317X37800357MVGGAQQ', 'P-9PM92809LR3522351MVGGBSY'] // normal price
        //planIdsToOffer = ['P-468965743R4646715MWWRHVA', 'P-4VY66052VB5787028MWWRIMY', 'P-17N1774715619232NMWWRJOA'] // Discounted 5€ old price
        otherPlansToLoad = ['P-468965743R4646715MWWRHVA', 'P-4VY66052VB5787028MWWRIMY', 'P-17N1774715619232NMWWRJOA'] // Discounted 5€ old price for display
    } else {
        planIdsToOffer = ['P-9P8577366W311972GM63L2XY', 'P-38587840EK885471SMUI2HAI', 'P-77X68366H0869435FMUI2KYI'] // normal price with monthly trial
        // planIdsToOffer = ['P-9WP439509G520810BMUI2KCY', 'P-38587840EK885471SMUI2HAI', 'P-77X68366H0869435FMUI2KYI'] // normal price
        otherPlansToLoad = ['P-9EV58178Y7864172XMUNQNHI']
    }

    var promises: Promise<any>[] = []
    var loadedSpecs: Record<string, SubscriptionOptionSpec> = {}

    for (const planId of [...planIdsToOffer, ...otherPlansToLoad]) {
        promises.push(getBillingPlanCall(planId))
    }

    for (const p of promises) {
        const { status, billingPlan } = await p
        if (status === 200) {
            billingPlan as SubscriptionOptionSpec
            billingPlan.offerForSale = planIdsToOffer.includes(billingPlan.id)
            loadedSpecs[billingPlan.id] = billingPlan
        }
    }

    dispatch(setSubscriptionOptions(loadedSpecs))
}

export function getDiscountReason(date: Date = new Date()): string {
    const month = date.getMonth() + 1 // getMonth() is 0-based
    const day = date.getDate()

    if (month === 1 && day <= 7) return "New Year Sale"
    if ((month === 1 && day >= 8) || (month === 2 && day <= 14)) return "Winter Sale"
    if (month === 2 && day >= 15) return "Valentine's Discount"
    if (month === 3) return "Spring Discount"
    if (month === 4 && day <= 15) return "Easter Special"
    if ((month === 4 && day > 15) || (month === 5 && day <= 15)) return "Spring Discount"
    if ((month === 5 && day > 15) || (month === 6 && day <= 15)) return "Early Summer Sale"
    if ((month === 6 && day > 15) || (month === 7)) return "Summer Discount"
    if ((month === 8 && day <= 10)) return "Back to School Discount"
    if ((month === 8 && day > 10) || (month === 9 && day <= 30)) return "Late Summer Sale"
    if (month === 10 && day <= 24) return "Autumn Discount"
    if ((month === 10 && day > 24) || (month === 11 && day <= 5)) return "Halloween Special"
    if ((month === 11 && day > 5) && (day <= 24)) return "Early Winter Discount"
    if (month === 11 && day >= 25) return "Black Friday Sale"
    if (month === 12) return "Christmas Discount"

    return "Special Discount" // Fallback, should never be reached
}
