import { MutableRefObject as Ref } from 'react'
import {
  getSlideCall, getPresentationsCall, getPresentationCall, writeSlideCall, deleteSlideCall,
  getMastersCall, postPresentationCall, putPresentationCall, postMasterFileCall, deleteMasterCall, deletePresentationCall, getSlideFileCall, getPresentationFileCall, postMasterCall, uploadFileUsingPresignedURL, patchMasterCall, initFromCommonCall, patchSlideCall, patchPresentationCall,
  getImageUploadUrlCall,
  createSlideAiAssistCall,
  postPresentationAiOutlineCall,
  postMasterFromTemplateCall
} from '../lib/restCalls'
import { initLogicalSlide, initRenderDetail, initSinglePresentation, noHyphenUuid } from '../lib/init'
import { setSingleSlide, setSlideRenderDetail, setLogicalSlide, setSlides, setSlideElement, removeSlideElement, setLogicalSlideWithVersionBump } from '../store/slidesSlice'
import { setPresentations, setSinglePresentation, removeSlideId, removeSinglePresentation } from '../store/presentationsSlice'
import { setMasters, setSingleMaster, removeSingleMaster } from '../store/mastersSlice'
import { setCurrentPresentation, setCurrentSlide, setPresentationPanelVisible, setPresentationSelection, setSlidePanelVisible } from '../store/presentationSelectionSlice'
import { logicalSlideChangeTypeDelays, initPresentationSlideSelection } from '../lib/init'
import { loadSubscriptionPlans } from './subscriptionHandling'
import { setSubscriptionOptions } from '../store/subscriptionOptionSlice'
import { addMasterAnalysisLoadingState, cleanMasterAnalysisLoadingStates, setLoadingState } from '../store/loadingStateSlice'
import { sleep } from './misc'
import { Actions, initialPresent } from '../components/helpers/UseHistory'
import { getCurrentAuthenticatedUser } from './auth'
import { getValidStorageSessionId } from './noLoginSessions'
import { sanitizeFilename } from './text'

//////////////////////////////////////////
// INIT AND EXIT
//////////////////////////////////////////

// empty master, presi & slide store
export const emptyUserStore = async (dispatch: any, histActions: Actions) => {
  histActions.reset(initialPresent)
  dispatch(setPresentations({}))
  dispatch(setSlides({}))
  dispatch(setMasters({}))
  dispatch(setSubscriptionOptions({}))
  dispatch(setPresentationSelection(initPresentationSlideSelection))
  dispatch(setLoadingState({ MASTERANALYSIS: [] }))
}

// init master, presi & slide store
export const initUserStore = async (dispatch: any, renderingOptions: RenderOptions, histActions: Actions, login: boolean = false) => {
  emptyUserStore(dispatch, histActions)
  const presiPromise = loadPresentations(dispatch, {}, renderingOptions)
  await sleep(200) // if all is done parallel, we create a lot of unneccessary Sessions
  const masterPromise = loadMasters(dispatch)
  await sleep(200)
  loadSubscriptionPlans(dispatch)

  const presiCount = await presiPromise
  const masterCount = await masterPromise

  // if we have no masters and presis we init from common
  if (presiCount === 0 && masterCount === 0) {
    // if it's a login we take over the session, if not we want the common example presis and set session = undefined
    const sessionId = login ? getValidStorageSessionId() : undefined
    let { status } = await initFromCommonCall(sessionId)
    if (status === 200) {
      loadPresentations(dispatch, {}, renderingOptions)
      loadMasters(dispatch)
    }
    if (login) { // empty account after login almost surely is a sign_up
      const currentUser = await getCurrentAuthenticatedUser()
      window.gtag('set', 'user_data', {
        "email": currentUser?.attributes?.email ? currentUser.attributes.email.trim().toLowerCase() : ""
      })
      //skai17
      window.gtag('event', 'conversion', { 'send_to': 'AW-404729103/HJ0PCN3Y4ZUaEI_a_sAB' })
      //quasarfox
      window.gtag('event', 'conversion', { 'send_to': 'AW-11454548033/5GXjCLbZ6JUaEMGQ-tUq' })
      window.gtag('event', 'sign_up')
    }
  }

}

//////////////////////////////////////////
// PRESENTATIONS
//////////////////////////////////////////


// load presentations from backend of given owner and images of the first slide of each presentation
export const loadPresentations = async (dispatch: any, slides: Slides, renderingOptions: RenderOptions) => {
  let { status, presentations } = await getPresentationsCall()
  if (status === 200 && presentations !== undefined) {
    dispatch(setPresentations(presentations))

    // // set the first presentation and its first slide as selected
    // if (Object.values(presentations).length > 0) {
    //   const firstPresi = Object.values(presentations)[0]
    //   if (firstPresi.presentationId) dispatch(setCurrentPresentation(firstPresi.presentationId))
    //   if (firstPresi.slideIds.length > 0) dispatch(setCurrentSlide(firstPresi.slideIds[0]))
    // }

    // get the rendering of the first slide of each presentation
    for (const pId in presentations) {
      if (presentations[pId].slideIds.length > 0) {
        let slideId: string = presentations[pId].slideIds[0]
        updateSlideRendering(dispatch, slides, pId, slideId, renderingOptions, false)
      }
    }

    // when we have zero presis the user get's everything from common
    return Object.keys(presentations).length
  }
  return undefined
}

// load single presentation from backend and image of the first slide
export const loadPresentation = async (dispatch: any, presentationId: string, slides: Slides | null, renderingOptions: RenderOptions | null, getFirstSlideRendering: boolean) => {
  let { status, singlePresentation } = await getPresentationCall(presentationId)
  if (status === 200 && singlePresentation !== undefined) {
    dispatch(setSinglePresentation({ presentationId: presentationId, singlePresentation: singlePresentation }))

    // get the rendering of the first slide of the presentation
    if (getFirstSlideRendering && renderingOptions && slides) {
      if (singlePresentation.slideIds.length > 0) {
        let slideId: string = singlePresentation.slideIds[0]
        updateSlideRendering(dispatch, slides, presentationId, slideId, renderingOptions, false)
      }
    }
    return singlePresentation as SinglePresentation
  }
  return undefined
}


// create a new presentation
export const createPresentation = async (dispatch: any, title: string, masterId: string, slides: Slides, renderingOptions: RenderOptions) => {

  // create the presi
  let { status, singlePresentation } = await postPresentationCall(initSinglePresentation(title, masterId))
  if (status === 200 && singlePresentation) {
    dispatch(setSinglePresentation({ presentationId: singlePresentation.presentationId, singlePresentation: singlePresentation }))
    // add first slide
    createSlide(dispatch, singlePresentation?.presentationId, slides, renderingOptions)
  }
}

// create a new presentation with ai
export const createPresentationAiAssist = async (dispatch: any, title: string, masterId: string, userPrompt: string, slides: Slides, renderingOptions: RenderOptions, createTitleSlide: boolean, createToC: boolean) => {

  // get presentation outline for user prompt
  let outlineResponse = await postPresentationAiOutlineCall(userPrompt, createToC)
  if (outlineResponse.status === 200 && outlineResponse.presentationOutline && outlineResponse.presentationTopic && outlineResponse.presentationTitle) {

    // create the presi
    let { status, singlePresentation } = await postPresentationCall(initSinglePresentation(title || outlineResponse.presentationTitle, masterId))
    if (status === 200 && singlePresentation && singlePresentation.presentationId) {
      const presentationId = singlePresentation.presentationId

      dispatch(setSinglePresentation({ presentationId: presentationId, singlePresentation: singlePresentation }))

      // request ai slide creation for every slide in outline
      const logiSlidePromisesList = outlineResponse.presentationOutline.map((slideRequest, index) => createSlideAiAssist(dispatch, presentationId, outlineResponse.presentationTopic + ' ' + slideRequest.slideContentRequest, slideRequest.slideTitle, slides, renderingOptions, index + (createTitleSlide ? 1 : 0)))
      const titleSlide = createTitleSlide ? await createSlide(dispatch, presentationId, slides, renderingOptions, undefined, 0, title || outlineResponse.presentationTitle, true) : undefined

      return { logiSlidePromisesList, singlePresentation, titleSlide }
    }
  }
  return
}

// duplicate presentation
export const duplicatePresentation = async (dispatch: any, currentPresentation: SinglePresentation, slides: Slides, renderingOptions: RenderOptions, histActions: Actions, currentSlideId: string | undefined) => {
  // create the new presi
  let { status, singlePresentation } = await postPresentationCall({ ...currentPresentation, title: currentPresentation.title + ' copy', presentationId: undefined, publishedFromCommon: false, slideIds: [] })
  if (status === 200 && singlePresentation && singlePresentation.presentationId) {
    dispatch(setSinglePresentation({ presentationId: singlePresentation.presentationId, singlePresentation: singlePresentation }))

    for (const slideId of currentPresentation.slideIds) {
      await createSlide(dispatch, singlePresentation.presentationId, slides, renderingOptions, { ...slides[slideId].logicalSlide, slideId: noHyphenUuid(), presentationId: singlePresentation.presentationId })

      // switch to presi panel after first slide is created
      if (slideId === currentPresentation.slideIds[0]) {
        histActions.set({
          do: () => { dispatch(setPresentationPanelVisible()); dispatch(setCurrentSlide(undefined)) },
          undo: () => { dispatch(setSlidePanelVisible()); dispatch(setCurrentSlide(currentSlideId)); dispatch(setCurrentPresentation(currentPresentation.presentationId)) },
          stepTitle: 'Go to presentation list'
        })
      }
    }
  }
}


// update presentation and optionally rerender slides
export const updatePresentation = async (dispatch: any, newPresentation: SinglePresentation, rerenderSlides: boolean, slides: Slides | null, renderingOptions: RenderOptions, versionChecksettings?: { histActions: Actions, doVersionCheck: boolean }) => {
  const skipVersionCheck = !(versionChecksettings?.doVersionCheck)

  // change in store
  dispatch(setSinglePresentation({ presentationId: newPresentation.presentationId, singlePresentation: newPresentation }))

  // change in backend
  let { status, singlePresentation } = await putPresentationCall(newPresentation, skipVersionCheck)
  if (status === 200 && singlePresentation !== undefined) {
    dispatch(setSinglePresentation({ presentationId: singlePresentation.presentationId, singlePresentation: singlePresentation }))

    if (rerenderSlides && slides && singlePresentation.presentationId && renderingOptions) {
      for (let slideId of singlePresentation.slideIds) {
        updateSlideRendering(dispatch, slides, singlePresentation.presentationId, slideId, renderingOptions, true)
      }
    }
  } else if (status === 409 && versionChecksettings?.histActions) {
    alert('Data was changed outside this browser tab. Reload from server required!')
    initUserStore(dispatch, renderingOptions, versionChecksettings.histActions)
  }
}

// delete a presentation
export const deletePresentation = async (dispatch: any, presentationId: string | undefined, permanent: boolean = false) => {
  if (presentationId) {

    // delete the presentation
    let { status } = await deletePresentationCall(presentationId, permanent)
    if (status === 200) {
      // remove the presentation from store
      dispatch(removeSinglePresentation({ presentationId: presentationId }))
      // reset current slide selection
      dispatch(setPresentationSelection(initPresentationSlideSelection))
    }
  }
}

// restore a presentation
export const restorePresentation = async (dispatch: any, presentationId: string | undefined, slides: Slides, renderingOptions: RenderOptions) => {
  if (presentationId) {

    // restore the slide
    let { status } = await patchPresentationCall(presentationId, { deleted: false })
    if (status === 200) {
      //update the presentation from backend
      loadPresentation(dispatch, presentationId, slides, renderingOptions, true)
    }
  }
}


//////////////////////////////////////////
// SLIDES
//////////////////////////////////////////


// if we know the slide already we get the most recent version from backend to check for the version of the rendering
// if required, rendering call is done and state updated
export const updateSlideRendering = async (dispatch: any, slides: Slides, pId: string, slideId: string, renderingOptions: RenderOptions, forceRendering: boolean) => {

  let renderingRequired = false

  if (slideId in slides && !forceRendering) {
    let { status, logicalSlide } = await getSlideCall(pId, slideId, null)
    if (status === 200 && logicalSlide !== undefined) {
      if (logicalSlide.version !== slides[slideId].renderDetail.version) renderingRequired = true
    } else renderingRequired = true
  } else renderingRequired = true

  if (renderingRequired) {
    let { status, slideRenderDetail, logicalSlide } = await getSlideCall(pId, slideId, renderingOptions)
    if (status === 200 && slideRenderDetail !== undefined && logicalSlide !== undefined) {
      dispatch(setSingleSlide({ slideId: slideId, singleSlide: { logicalSlide: logicalSlide, renderDetail: slideRenderDetail } }))
    }
  }
}


// load slide of a presentation without rendering first, then add rendering. Gives placeholders and better UX for user
export const loadSlideInTwoSteps = async (dispatch: any, slides: Slides, presentation: SinglePresentation, slideId: string, renderingOptions: RenderOptions) => {
  if (presentation.presentationId) {
    // get the slide without rendering only if we don't know it yet
    if (!(slideId in slides)) {
      let { status, logicalSlide } = await getSlideCall(presentation.presentationId, slideId, null)
      if (status === 200 && logicalSlide !== undefined) {
        dispatch(setSingleSlide({ slideId: slideId, singleSlide: { logicalSlide: logicalSlide, renderDetail: initRenderDetail() } }))
      }
    }
    updateSlideRendering(dispatch, slides, presentation.presentationId, slideId, renderingOptions, false)
  }
}


// load slides of given presentation
export const loadSlides = async (dispatch: any, slides: Slides, presentationId: string | undefined, presentation: SinglePresentation | undefined, renderingOptions: RenderOptions) => {
  if (presentationId && presentation) {
    const currentLogicalPresentation = await loadPresentation(dispatch, presentationId, slides, renderingOptions, false)

    var slideIdList
    if (currentLogicalPresentation !== undefined) { slideIdList = currentLogicalPresentation.slideIds }
    else { slideIdList = presentation.slideIds }

    // initially load all slides we do not know yet quickly without rendering for better user experience
    for (const slideId of slideIdList) {
      loadSlideInTwoSteps(dispatch, slides, currentLogicalPresentation ? currentLogicalPresentation : presentation, slideId, renderingOptions)
    }

  }
}

// get slides file
export const downloadSlideFile = async (presentationId: string, slideId: string, title: string | undefined, slidePosition: number | undefined) => {
  let { status, pptxBase64 } = await getSlideFileCall(presentationId, slideId)
  if (status === 200 && pptxBase64) {
    const link = document.createElement("a")
    link.download = title && slidePosition !== undefined ? sanitizeFilename(title) + ' Slide ' + String(slidePosition + 1) + '.pptx' : `slide.pptx`
    link.href = "data:application/vnd.openxmlformats-officedocument.presentationml.presentation;base64," + pptxBase64
    link.click()
  }
}

// get presentation file
export const downloadPresentationFile = async (presentationId: string, title: string | undefined) => {
  let { status, pptxBase64 } = await getPresentationFileCall(presentationId)
  if (status === 200 && pptxBase64) {
    const link = document.createElement("a")
    link.download = title ? sanitizeFilename(title) + '.pptx' : `presentation.pptx`
    link.href = "data:application/vnd.openxmlformats-officedocument.presentationml.presentation;base64," + pptxBase64
    link.click()
  }
}

// create a new slide
export const createSlide = async (dispatch: any, presentationId: string | undefined, slides: Slides, renderingOptions: RenderOptions, requestLogicalSlide: LogicalSlide | undefined = undefined, position: number | undefined = undefined, slideTitle: string | undefined = undefined, useTitleLayout: boolean | undefined = undefined) => {
  if (presentationId) {

    // create the slide
    let { status, logicalSlide } = await writeSlideCall(requestLogicalSlide || initLogicalSlide(presentationId), null, "POST", position, undefined, slideTitle, useTitleLayout)
    if (status === 200 && logicalSlide) {
      dispatch(setSingleSlide({ slideId: logicalSlide.slideId, singleSlide: { logicalSlide: logicalSlide, renderDetail: initRenderDetail() } }))

      //update the presentation from backend
      await loadPresentation(dispatch, presentationId, slides, renderingOptions, false)

      // update slide rendering
      updateSlideRendering(dispatch, slides, presentationId, logicalSlide.slideId, renderingOptions, false)

      return logicalSlide
    }
  }
  return undefined
}

// create a new slide using aiassist
export const createSlideAiAssist = async (dispatch: any, presentationId: string, userPrompt: string, slideTitle: string | undefined, slides: Slides, renderingOptions: RenderOptions, position: number | undefined = undefined) => {

  // create the slide
  let { status, logicalSlide } = await createSlideAiAssistCall(presentationId, userPrompt, slideTitle, position)
  if (status === 200 && logicalSlide) {
    dispatch(setSingleSlide({ slideId: logicalSlide.slideId, singleSlide: { logicalSlide: logicalSlide, renderDetail: initRenderDetail() } }))

    //update the presentation from backend
    await loadPresentation(dispatch, presentationId, slides, renderingOptions, false)

    // update slide rendering
    updateSlideRendering(dispatch, slides, presentationId, logicalSlide.slideId, renderingOptions, false)

    return logicalSlide
  }
  return undefined
}


// delete a slide
export const deleteSlide = async (dispatch: any, presentation: SinglePresentation | undefined, slideId: string | undefined, slides: Slides, renderingOptions: RenderOptions) => {
  if (presentation && presentation.presentationId && slideId) {

    // set the new selected slide
    const currentIndex = presentation.slideIds.findIndex((element) => element === slideId)
    var newIndex = undefined
    if (presentation.slideIds.length > 1) {
      if (presentation.slideIds.length - 1 === currentIndex) newIndex = presentation.slideIds.length - 2
      else newIndex = currentIndex + 1
    }
    if (newIndex) dispatch(setCurrentSlide(presentation.slideIds[newIndex]))
    else dispatch(setCurrentSlide(undefined))


    // delete the slide
    let { status } = await deleteSlideCall(presentation.presentationId, slideId)
    if (status === 200) {
      // remove the slide from the presentations slide list
      dispatch(removeSlideId({ presentationId: presentation.presentationId, slideId: slideId }))
      //update the presentation from backend
      loadPresentation(dispatch, presentation.presentationId, null, null, false)
      // if the presentation has 0 slides now we add a new one
      if (presentation.slideIds.length === 1) {
        createSlide(dispatch, presentation?.presentationId, slides, renderingOptions)
      }
    }
  }
}


// restore a slide
export const restoreSlide = async (dispatch: any, presentation: SinglePresentation | undefined, slideId: string | undefined, slides: Slides, renderingOptions: RenderOptions, position: number) => {
  if (presentation && presentation.presentationId && slideId) {

    // restore the slide
    let { status } = await patchSlideCall(presentation.presentationId, slideId, { deleted: false }, position)
    if (status === 200) {
      //update the presentation from backend
      loadPresentation(dispatch, presentation.presentationId, slides, renderingOptions, true)
    }
  }
}


export const handleLogicalSlideChange = async (dispatch: any, changedLogicalSlide: LogicalSlide | undefined, currentSlideId: string | undefined, logicalSlideVersionRef: Ref<Record<string, number | undefined>>, renderVersionRef: Ref<Record<string, number | undefined>>, renderingOptions: RenderOptions, histActions: Actions) => {
  if (currentSlideId && logicalSlideVersionRef.current[currentSlideId] !== undefined && renderVersionRef.current[currentSlideId] !== undefined && changedLogicalSlide) {

    // remember current version of the change
    let thisVersion = logicalSlideVersionRef.current[currentSlideId]

    // sleep to wait for other quick changes
    let delay = changedLogicalSlide.lastChangeType in logicalSlideChangeTypeDelays ? logicalSlideChangeTypeDelays[changedLogicalSlide.lastChangeType] : 0
    await new Promise(r => setTimeout(r, delay))

    // call putSlide only if this very call is still the latest && version is larger than the current render version
    if (thisVersion !== undefined && thisVersion === logicalSlideVersionRef.current[currentSlideId] && (logicalSlideVersionRef.current[currentSlideId] || -1) > (renderVersionRef.current[currentSlideId] || -2)) {
      let { status, slideRenderDetail, logicalSlide } = await writeSlideCall(changedLogicalSlide, renderingOptions, 'PUT')
      if (status === 200 && slideRenderDetail !== undefined && logicalSlide !== undefined) {
        if (thisVersion >= (renderVersionRef.current[currentSlideId] || -1)) {
          dispatch(setSlideRenderDetail({ slideId: currentSlideId, renderDetail: { ...slideRenderDetail, version: thisVersion } }))
        }
        if (thisVersion >= (logicalSlideVersionRef.current[currentSlideId] || -1)) {
          dispatch(setLogicalSlide({ slideId: changedLogicalSlide.slideId, logicalSlide: logicalSlide }))
        }
      } else if (status === 409) {
        if (thisVersion >= (logicalSlideVersionRef.current[currentSlideId] || -1)) {
          alert('Data was changed outside this browser tab. Reload from server required!')
          initUserStore(dispatch, renderingOptions, histActions)
        }
      }
      else {
      }
    } else {
    }
  }
}

export const handleAutoAlign = async (dispatch: any, currentLogicalSlide: LogicalSlide | undefined, currentSlideId: string | undefined, histActions: Actions) => {
  if (currentSlideId && currentLogicalSlide) {
    let { status, logicalSlide } = await writeSlideCall(currentLogicalSlide, null, 'PUT', undefined, true)
    if (status === 200 && logicalSlide !== undefined) {
      histActions.set({
        do: () => dispatch(setLogicalSlideWithVersionBump({ slideId: currentLogicalSlide.slideId, logicalSlide: logicalSlide })),
        undo: () => dispatch(setLogicalSlideWithVersionBump({ slideId: currentLogicalSlide.slideId, logicalSlide: currentLogicalSlide })),
        stepTitle: 'Auto align elements'
      })

    }
  }
  return true
}


//////////////////////////////////////////
// MASTERS
//////////////////////////////////////////


// load masters from backend 
export const loadMasters = async (dispatch: any) => {
  let { status, masters } = await getMastersCall()
  if (status === 200 && masters !== undefined) {
    dispatch(setMasters(masters))
    return Object.keys(masters).length
  }
  return undefined
}

// add new master from file
export const addMasterFromFile = async (dispatch: any, masterFile: File) => {
  // read the file
  const reader = new FileReader()
  reader.onabort = () => { return undefined }
  reader.onerror = () => { return undefined }
  reader.onload = async () => {
    if (reader.result) {
      let base64String = reader.result as string
      base64String = base64String.replace('data:', '').replace(/^.+,/, '')

      // post master to backend
      var { status, masters } = await postMasterFileCall({ filename: masterFile.name, masterBase64: base64String }, true)
      if (status === 200 && masters !== undefined) {
        for (let singleMaster of masters) {
          dispatch(setSingleMaster({ masterId: singleMaster.logicalMaster.masterId, singleMaster: singleMaster }))
        }
      }
    }
  }
  reader.readAsDataURL(masterFile)
}

// add new master from file async
export const addMasterFromFileAsync = async (dispatch: any, masterFile: File) => {
  let { status, data } = await postMasterCall(masterFile.name)
  if (status === 200 && data !== undefined) {
    // set the loading state 
    dispatch(addMasterAnalysisLoadingState({ masterId: data.masterId, filename: masterFile.name, starttime: Date.now() }))
    let { status } = await uploadFileUsingPresignedURL(data.uploadUrl, masterFile)
    if (status !== 200) {
      // remove the loading state on upload failure
      dispatch(cleanMasterAnalysisLoadingStates({ masterIdsToRemove: [data.masterId] }))
      alert('Upload failed. Please try again')
    }
  }
}

// add new master from template async
export const addMasterFromTemplateAsync = async (dispatch: any, masterName: string, masterTemplateType: MasterTemplateType, primaryColorHex: string) => {
  let { status, data } = await postMasterFromTemplateCall(masterName, masterTemplateType, primaryColorHex)
  if (status === 200 && data !== undefined) {
    // set the loading state 
    dispatch(addMasterAnalysisLoadingState({ masterId: data.masterId, filename: masterName, starttime: Date.now() }))
  }
}

// delete a master
export const deleteMaster = async (dispatch: any, masterId: string | undefined, permanent: boolean = false) => {
  if (masterId) {

    // delete the master
    let { status } = await deleteMasterCall(masterId, permanent)
    if (status === 200) {
      // remove the master from store
      dispatch(removeSingleMaster({ masterId: masterId }))
      //update the masters from backend
      //loadMasters(dispatch)
    }
  }
}

// patch a master
export const patchMaster = async (dispatch: any, masterId: string | undefined, patchPayload: MasterPatchPayload) => {
  if (masterId) {
    // patch the master
    let { singleMaster, status } = await patchMasterCall(masterId, patchPayload)
    if (status === 200 && singleMaster) {
      // Update the master in store
      dispatch(setSingleMaster({ masterId: singleMaster.logicalMaster.masterId, singleMaster: singleMaster }))
    }
  }
}

//////////////////////////////////////////
// ElEMENTS
//////////////////////////////////////////

export const handleElementPaste = async (dispatch: any, currentSlideId: string | undefined, clipboard: ClipboardType, histActions: Actions) => {
  if (currentSlideId && clipboard.logicalElement) {
    const elId = noHyphenUuid()
    const shift = (currentSlideId === clipboard.logicalElement.slideId) ? 0.02 : 0 // on a different slide we use the same position
    const newElement: LogicalElement = {
      ...clipboard.logicalElement, slideId: currentSlideId, elementId: elId,
      box: { ...clipboard.logicalElement.box, left: clipboard.logicalElement.box.left + shift, top: clipboard.logicalElement.box.top + shift }
    }
    histActions.set({
      do: () => dispatch(setSlideElement({ slideId: currentSlideId, elementId: elId, logicalElement: newElement })),
      undo: () => dispatch(removeSlideElement({ slideId: currentSlideId, elementId: elId })),
      stepTitle: 'Paste element'
    })

  }
}

//////////////////////////////////////////
// IMAGES
//////////////////////////////////////////

// add new image from file
export const uploadImage = async (imageFile: File) => {
  // get presigned upload URL
  let { status, data } = await getImageUploadUrlCall()
  if (status === 200 && data !== undefined) {
    // upload file to presigned url
    let { status } = await uploadFileUsingPresignedURL(data.uploadUrl, imageFile)
    if (status === 200) {
      return data.imageId
    } else {
      alert('Upload failed. Please try again')
      return false
    }
  } else {
    alert('Upload failed. Please try again')
    return false
  }
}