import React, { Suspense, useEffect, useState } from 'react'
import { createTheme, SxProps, ThemeProvider } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import TopLevelRouter from './components/navi/TopLevelRouter'
import CssBaseline from '@mui/material/CssBaseline'
import Container from '@mui/material/Container'
import { detectImageSupport } from './lib/rendering'
import { useAppDispatch } from './store/hooks'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { BrowserRouter as Router } from 'react-router-dom'
import { AmplifyProvider } from '@aws-amplify/ui-react'
import { getAmplifyTheme } from './lib/auth'
import { PayPalScriptProvider, ReactPayPalScriptOptions } from '@paypal/react-paypal-js'
import { OnlineStatusProvider } from './components/helpers/UseOnlineStatus'
import { HistoryProvider } from './components/helpers/UseHistory'
import { HotkeysProvider } from 'react-hotkeys-hook'
import { globalHotKeyScope } from './lib/constants'
import { Box, Typography } from '@mui/material'


declare module '@mui/material/styles' {
  interface Theme {
    customStyling: {
      relRndPadding: number
      relRndBoarderRadius: string
      componentActiveOpacity: number
      paleBgColorOpacity: number
      blur: string
      panelGridItemSx: SxProps
      panelGridItemElevation: number
      panelGridSpacing: number
      panelGridItemTitleSx: SxProps
      panelInnerGridItemSx: SxProps
      heroHeaderFontSize: number
      heroSubtitleFontSize: number
      headerHeight: number
    }
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    customStyling?: {
      relRndPadding?: number
      relRndBoarderRadius?: string
      componentActiveOpacity?: number
      paleBgColorOpacity?: number
      blur?: string
      panelGridItemSx?: SxProps
      panelGridItemElevation?: number
      panelGridSpacing?: number
      panelGridItemTitleSx?: SxProps
      panelInnerGridItemSx?: SxProps
      heroHeaderFontSize: number
      heroSubtitleFontSize: number
      headerHeight: number
    }
  }
}

const paypalOptions: ReactPayPalScriptOptions = {
  "clientId": `${process.env.REACT_APP_STAGE === process.env.REACT_APP_NAME_OF_PROD_STAGE ?
    process.env.REACT_APP_PAYPAL_CLIENT_PROD :
    process.env.REACT_APP_PAYPAL_CLIENT_NONPROD}`,
  components: "buttons",
  intent: "subscription",
  currency: "EUR",
  vault: true, // to store the payment info. Required for subscription
  disableFunding: "credit,sepa,sofort,ideal,bancontact,blik,venmo,giropay,eps,mybank,p24,mercadopago",
}

function App() {
  const [editorThemeProps, setEditorThemeProps] = useState<EditorThemeProps | undefined>(undefined)

  const setEditorTheme = (themeProps: EditorThemeProps | undefined) => { setEditorThemeProps(themeProps) }

  // Theme  (https://m2.material.io/inline-tools/color/)
  // https://bareynol.github.io/mui-theme-creator/

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: editorThemeProps ? editorThemeProps.mode : (prefersDarkMode ? 'dark' : 'light'),
          primary: {
            main: editorThemeProps ? editorThemeProps.primaryColor : "#1976d2", //clean business 5697D6 1976d2
          },
          secondary: {
            main: editorThemeProps ? editorThemeProps.secondaryColor : "#FFE0B2", //"#42cacE", turkis
          }
        },
        customStyling: {
          relRndPadding: 1,
          relRndBoarderRadius: "5px",
          componentActiveOpacity: 0.3,
          paleBgColorOpacity: 0.03,
          blur: "blur(5px)",
          panelGridItemSx: { width: 1, height: 1, p: 3, display: 'flex', alignItems: 'flex-start', flexWrap: 'wrap', alignContent: 'flex-start', backgroundColor: 'transparent' },
          panelGridItemElevation: 0,
          panelGridSpacing: 2,
          panelGridItemTitleSx: { mb: 1, width: 1, display: 'flex', alignItems: 'flex-start', flexWrap: 'wrap' },
          panelInnerGridItemSx: { width: 1, pb: 2, display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', alignItems: 'flex-start', alignContent: 'flex-start' },
          heroHeaderFontSize: 2.5,
          heroSubtitleFontSize: 1.5,
          headerHeight: 60
        }
      }),
    [prefersDarkMode, editorThemeProps],
  )

  // state access
  const dispatch = useAppDispatch()

  // Start up hook
  useEffect(() => {
    // Detect supported image formats
    detectImageSupport(dispatch)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const amplifyTheme = getAmplifyTheme(theme)
  return (
    <ThemeProvider theme={theme}>
      <DndProvider backend={HTML5Backend}>
        <PayPalScriptProvider options={paypalOptions}>
          <AmplifyProvider theme={amplifyTheme}>
            <OnlineStatusProvider>
              <HistoryProvider>
                <HotkeysProvider initiallyActiveScopes={[globalHotKeyScope]}>
                  <Router>
                    <Suspense fallback={<Box sx={{ width: 1, pt: 20, display: 'flex', justifyContent: 'center', alignContent: 'center' }}><Typography variant="button" color="text.secondary" >Loading...</Typography></Box>}>
                      <CssBaseline />
                      <Container maxWidth={false} disableGutters sx={{ minHeight: '100vh', overflow: 'hidden' }}>
                        <TopLevelRouter setEditorTheme={setEditorTheme} />
                      </Container>
                    </Suspense>
                  </Router>
                </HotkeysProvider>
              </HistoryProvider>
            </OnlineStatusProvider>
          </AmplifyProvider>
        </PayPalScriptProvider>
      </DndProvider>
    </ThemeProvider>
  )
}

export default App
