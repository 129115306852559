import { loremIpsum } from "lorem-ipsum"
import { Descendant } from 'slate'
import { capitalizeFirstLetter } from '../lib/misc'

export const paragraphFromText = (txt: string): Descendant[] => {
    return [{ type: 'paragraph', children: [{ text: txt }] }]
}

export const richLoremParagraph = (words: number): Descendant[] => {
    return paragraphFromText(capitalizeFirstLetter(loremIpsum({ count: words, units: "words" })) + '.')
}

export const plainLoremText = (words: number): string => {
    return capitalizeFirstLetter(loremIpsum({ count: words, units: "words" })) + '.'
}

export function sanitizeFilename(input: string, replacement = "") {
    // Define invalid characters dynamically
    const controlChars = Array.from({ length: 32 }, (_, i) => String.fromCharCode(i)).join("")
    const INVALID_CHARS_REGEX = new RegExp(`[<>:"/\\\\|?*${controlChars}]`, "g") // Windows + Linux/Mac
    const RESERVED_NAMES_REGEX = /^(CON|PRN|AUX|NUL|COM[1-9]|LPT[1-9])(\..*)?$/i // Windows reserved names
    const MAX_LENGTH = 255 // Safe filename length

    // Replace invalid characters
    let filename = input.replace(INVALID_CHARS_REGEX, replacement).trim()

    // Prevent reserved filenames in Windows
    if (RESERVED_NAMES_REGEX.test(filename)) {
        filename = "_" + filename
    }

    // Ensure filename is within the max length
    if (filename.length > MAX_LENGTH) {
        filename = filename.substring(0, MAX_LENGTH)
    }

    return filename || "file" // Avoid empty filenames
}