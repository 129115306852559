//import FacebookIcon from '@mui/icons-material/Facebook'
//import InstagramIcon from '@mui/icons-material/Instagram'
import { Box, Typography } from '@mui/material'
import Impressum from '../legal/Impressum'
import DataStatement from '../legal/DataStatement'
import { useState } from 'react'
import { useTheme } from '@mui/material/styles'
import TermsConditions from '../legal/TermsConditions'


const Footer = () => {
    const theme = useTheme()
    const isDarkMode = theme.palette.mode === "dark"


    // Impressum
    const [impressumStatus, setImpressumStatus] = useState(false)

    // Data Statement
    const [dataStatementStatus, setDataStatementStatus] = useState(false)

    // Terms Statement
    const [termsStatus, setTermsStatus] = useState(false)

    return (
        <Box sx={{ width: 1, display: 'grid', gridTemplateColumns: '1fr' }}>
            <Impressum impressumStatus={impressumStatus} setImpressumStatus={setImpressumStatus} />
            <DataStatement dataStatementStatus={dataStatementStatus} setDataStatementStatus={setDataStatementStatus} />
            <TermsConditions termsStatus={termsStatus} setTermsStatus={setTermsStatus} />

            {/* The bg arc */}
            <Box sx={{ gridColumnStart: 1, gridRowStart: 1, zIndex: 0, opacity: theme.customStyling.paleBgColorOpacity * (isDarkMode ? 2 : 1) }}>
                <svg preserveAspectRatio="none" style={{ display: 'block', height: '60px', width: '100%' }} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                    <path fill={theme.palette.primary.main} d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"></path>
                </svg>
            </Box>

            {/* The bg box under the arc */}
            <Box sx={{ gridColumnStart: 1, gridRowStart: 2, zIndex: 0, width: 1, minHeight: '40px', opacity: theme.customStyling.paleBgColorOpacity * (isDarkMode ? 2 : 1), backgroundColor: theme.palette.primary.main }}>
            </Box>

            {/* The footer content */}
            <Box sx={{ gridColumnStart: 1, gridRowStart: 1, gridRowEnd: 'span 2', pt: 2, mt: 3, zIndex: 1, display: 'grid', justifyContent: "center", alignItems: "center", alignContent: "center" }}>
                <Box sx={{ mb: 0.5, display: "flex", justifyContent: "center", alignItems: "center", alignContent: "center", flexWrap: "wrap" }}>
                    <Typography variant='body2' sx={{ textDecoration: "underline", color: "inherit", cursor: "pointer", ml: 1, mr: 1 }} onClick={() => { setTermsStatus(true) }}>
                        Terms & Conditions
                    </Typography>
                    <Typography variant='body2' sx={{ textDecoration: "underline", color: "inherit", cursor: "pointer", ml: 1, mr: 1 }} onClick={() => { setImpressumStatus(true) }}>
                        About us
                    </Typography>
                    <Typography variant='body2' sx={{ textDecoration: "underline", color: "inherit", cursor: "pointer", ml: 1, mr: 1 }} onClick={() => { setDataStatementStatus(true) }}>
                        Privacy statement
                    </Typography>
                </Box>
                {/* <Box sx={{ mb: 0.5, display: "flex", justifyContent: "center", alignItems: "center", alignContent: "center", flexWrap: "wrap" }}>
                    <a style={{ textDecoration: "none", color: "inherit", cursor: "pointer", }} href="https://www.facebook.com/Kickzteam-113004287717149" target="_blank" rel="noopener nofollow noreferrer">
                        <FacebookIcon />
                    </a>
                    <a style={{ textDecoration: "none", color: "inherit", cursor: "pointer", }} href="https://instagram.com/kickzteam" target="_blank" rel="noopener nofollow noreferrer">
                        <InstagramIcon />
                    </a>
                </Box> */}
            </Box>
        </Box>
    )
}

export default Footer
